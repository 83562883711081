import React from "react";
import * as s from "./logo.module.scss";

const Logo = () => {
  return (
    <div className={s.logo}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
        <g id="logo">
          <g id="Text_Circle" data-name="Text Circle" className={s.textRing}>
            <path
              className="cls-1"
              d="M540,76.64l-6.83-.79L518.81,0l6,.69,12.35,71L564,5.21l6.39.73,11.24,71,28.19-66.45,5.62.65-31,70.62L577.57,81,568.75,27.9l-2.27-16.33-.11,0L560.49,27Z"
            />
            <path
              className="cls-1"
              d="M671,51.91q4,6.87,1,16.36L661.3,102.92,656,101.26l10.2-32.95q2.61-8.43-.27-14t-10-7.75A20,20,0,0,0,645.81,46a18.69,18.69,0,0,0-9.06,4.7,21.37,21.37,0,0,0-5.83,9.64l-9.31,30.06-5.29-1.66L640.42,11l5.29,1.66L634.85,47.69a19.22,19.22,0,0,1,10.35-6.23,24.1,24.1,0,0,1,12.72.73Q667,45,671,51.91Z"
            />
            <path
              className="cls-1"
              d="M687.33,125.73q-6,5.56-10.7,6.74a14.54,14.54,0,0,1-9.93-1.27c-4.15-1.94-6.76-4.69-7.81-8.22l3.92-3.46c.48,3.19,2.47,5.59,6,7.23a10.23,10.23,0,0,0,6.89.83q3.38-.83,7.92-5.1l7-6.59.58-15,1.61-45.57L698.45,58l-2.36,45.59-.62,8,6.09-6L733.83,74.6l5.43,2.54Z"
            />
            <path
              className="cls-1"
              d="M756.55,166.48q-6.93,4.32-11.77,4.6a14.61,14.61,0,0,1-9.51-3.13q-5.58-4-6.15-9.56l4.5-2.65q-.18,4.83,4.5,8.22a10.17,10.17,0,0,0,6.61,2.12q3.48-.17,8.73-3.5l8.07-5.16,3.36-14.63L775,98.34l5.13,3.73L769.29,146.4l-2.1,7.7,7.1-4.76,37.47-24.28,4.85,3.53Z"
            />
            <path
              className="cls-1"
              d="M855.14,173.21q-.17,10.51-9.38,20.34t-19.62,10.59q-10.41.78-19.36-7.74t-8.77-19q.18-10.51,9.39-20.34T827,146.44q10.4-.76,19.35,7.74T855.14,173.21ZM827.56,151.8q-8.44.87-16.13,9.07t-8.08,16.75q-.39,8.55,6.71,15.29t15.54,5.88q8.43-.89,16.13-9.08T849.8,173q.39-8.55-6.7-15.3T827.56,151.8Z"
            />
            <path
              className="cls-1"
              d="M907.76,225.75l-43.45,34.68-3.09-3.94,8.46-7a19.5,19.5,0,0,1-12.2-.94,26.41,26.41,0,0,1-10.56-8.39q-5.65-7.19-5.07-14.63t8.31-13.61L878.84,189l3.44,4.38-27.63,22.06q-12.85,10.26-3.36,22.35a21.13,21.13,0,0,0,8.73,6.78,18,18,0,0,0,10,1,20.74,20.74,0,0,0,9.34-4.32l24.94-19.91Z"
            />
            <path
              className="cls-1"
              d="M894.5,305.88a17.79,17.79,0,0,1,4.85-12l4.7,2.77a13.7,13.7,0,0,0-4.45,9.82A26.35,26.35,0,0,0,902.67,319q3.35,6.81,7.83,9.37a8.78,8.78,0,0,0,8.75.44,7.59,7.59,0,0,0,4.24-6.23q.56-4.4-2-13.45-3.29-11.44-2.22-17.34a11.91,11.91,0,0,1,6.93-8.81,13.2,13.2,0,0,1,8.34-1.17A17.81,17.81,0,0,1,943,286a27.81,27.81,0,0,1,6.8,9.07q3.88,7.9,3.24,14.15a17.33,17.33,0,0,1-5.92,11.12l-4.6-3.07q8.87-7.9,2.91-20a21.53,21.53,0,0,0-4.86-6.61,14.27,14.27,0,0,0-5.93-3.4,7.7,7.7,0,0,0-5.53.46,8.14,8.14,0,0,0-4.69,6.57q-.62,4.54,2.13,13.9,3.31,11,2.21,16.84a11.88,11.88,0,0,1-6.88,8.66,13.14,13.14,0,0,1-12.86-.38q-6.51-3.54-11-12.75A31,31,0,0,1,894.5,305.88Z"
            />
            <path
              className="cls-1"
              d="M975.7,375.46q-3.19,7.28-12.55,10.43L929,397.45l-1.76-5.29,32.47-11q8.31-2.81,11.06-8.4t.4-12.68a20.34,20.34,0,0,0-5.51-8.62,18.65,18.65,0,0,0-9.09-4.64,21,21,0,0,0-11.17.89l-29.63,10-1.77-5.3,76.72-26,1.77,5.3-34.58,11.7A19.09,19.09,0,0,1,969,348.28a24.53,24.53,0,0,1,6.88,10.81Q978.9,368.2,975.7,375.46Z"
            />
            <path
              className="cls-1"
              d="M988.56,446.73q-6.34,8.36-19.54,10.74t-22-3.21q-8.84-5.61-11-17.81t4.16-20.56q6.33-8.36,19.54-10.74t22,3.21q8.84,5.61,11,17.81T988.56,446.73ZM979,413q-7.31-4.35-18.33-2.35t-16.37,8.63q-5.34,6.64-3.62,16.32t9,14Q957,454,968,452t16.37-8.64Q989.75,436.7,988,427T979,413Z"
            />
            <path
              className="cls-1"
              d="M999.88,520.52l-55.4,1.89-.17-5,11-.6a19.66,19.66,0,0,1-9.26-8.07,26.71,26.71,0,0,1-3.55-13.06q-.3-9.17,4.55-14.8t14.72-6l36.57-1.25.18,5.58-35.23,1.21q-16.39.56-15.88,16a21.26,21.26,0,0,0,3,10.68,18.22,18.22,0,0,0,7.44,6.85A20.73,20.73,0,0,0,967.9,516l31.8-1.09Z"
            />
            <path
              className="cls-1"
              d="M956.26,546.44c-3.39-.24-5.83.12-7.3,1.1s-2.31,2.73-2.48,5.26a15.59,15.59,0,0,0,1,7.46l-5.25-1.16a17.47,17.47,0,0,1-.92-7.45q.42-6,4-8.68c2.39-1.77,6-2.48,10.77-2.14l67.9,4.83-.39,5.57Z"
            />
            <path
              className="cls-1"
              d="M1012.57,630.26l-79.66-14.38.88-5,12.28,1.88a19.92,19.92,0,0,1-8.4-9.46A23.12,23.12,0,0,1,936.39,590q2.07-11.65,10.81-17.06t22-3q13.32,2.4,19.64,10.52t4.26,19.78A22.85,22.85,0,0,1,987.55,612a20.51,20.51,0,0,1-10.67,6.13l36.67,6.62Zm-68.23-23.89q4.8,6.32,14.54,8.52l4.58.83q9.91,1.21,16.58-3T988.22,600q1.71-9.57-3.62-16.08t-16.33-8.51q-11-2-18.26,2.26t-8.93,13.82Q939.55,600.06,944.34,606.37Z"
            />
            <path
              className="cls-1"
              d="M931.2,760.69q-7.32,3-16.31-1.38L882.5,743.66l2.39-5L916.78,754q7.2,3.48,12.82,1.37t8.78-8.77a19.1,19.1,0,0,0-1.48-19.36,20.29,20.29,0,0,0-8.07-6.81L900,706.55l2.4-5,31.89,15.41q7.18,3.47,12.81,1.36t8.78-8.77a19.61,19.61,0,0,0,1.93-9.72,18.82,18.82,0,0,0-3.31-9.59,21,21,0,0,0-8.52-7.15l-28.29-13.67,2.4-5,50,24.15L968,693l-10.14-4.53a20.13,20.13,0,0,1,4.6,11.26,23.55,23.55,0,0,1-2.33,12.38,22.92,22.92,0,0,1-8.37,9.83,17.34,17.34,0,0,1-11.9,2.55,18.3,18.3,0,0,1,5.12,11.58,25.28,25.28,0,0,1-2.44,13.25Q938.52,757.71,931.2,760.69Z"
            />
            <path
              className="cls-1"
              d="M847.08,798.68l-3.38-2.91a11.22,11.22,0,0,1,2.31-5.08,9.33,9.33,0,0,1,5.42-3.89c2.1-.49,4.61.21,7.55,2.1a17.14,17.14,0,0,1-.76-10.79,29.28,29.28,0,0,1,5-11.14q4.83-6.68,10.7-8.51t11.33,2.18q10.38,7.65,1,27l-7.59,15.58,3.94,2.9q5.37,3.95,10.73,3t9.62-6.83q7.78-10.72,1-21.41l5-1.58a19.56,19.56,0,0,1,3.32,12.54,26.13,26.13,0,0,1-5.35,13.35q-5.68,7.84-12.73,9.51T879.85,811l-23.09-17q-4.75-3.49-7.76.66A9.87,9.87,0,0,0,847.08,798.68Zm15.67-20a18.83,18.83,0,0,0-.5,8.84,10.87,10.87,0,0,0,4.4,6.9l8.33,6.13,6.72-14q4-8.29,4-13.41a9.2,9.2,0,0,0-3.76-7.88,7.74,7.74,0,0,0-7.53-1.26q-4,1.38-7.55,6.34A26.9,26.9,0,0,0,862.75,778.67Z"
            />
            <path
              className="cls-1"
              d="M819.26,806.72q-3.37-7.47-3-12.34a14.84,14.84,0,0,1,4.3-9.11q4.69-5.06,10.19-4.91l2,4.83q-4.72-.8-8.68,3.44a10.42,10.42,0,0,0-2.93,6.34q-.28,3.48,2.33,9.17l4,8.72,14,5.24L884,833.87l-4.33,4.65L837.39,822l-7.31-3.08,3.77,7.7,19.09,40.55-4.09,4.4Z"
            />
            <path
              className="cls-1"
              d="M797.48,906.4q-10.11-1.65-18.63-12.24t-8.05-20.9q.48-10.31,9.61-17.77a22.67,22.67,0,0,1,12.26-5.34A19.63,19.63,0,0,1,804.94,853l-7.56-9.92,3.88-3.17,51,63.33-4.31,3.51L824.47,877.6a20.65,20.65,0,0,1-.23,12.4,22.73,22.73,0,0,1-7.52,10.6Q807.6,908.07,797.48,906.4ZM776.3,873.45q-.19,8.43,6.85,17.19T798.44,901q8.23,1.53,15.73-4.58a18.13,18.13,0,0,0,7.14-13.47q.44-8-5.64-16.23l-2.52-3.13q-6.76-7.69-14.6-8.92T784,858.89Q776.5,865,776.3,873.45Z"
            />
            <path
              className="cls-1"
              d="M723.6,925.66l37.77-22.76q-5.72-8.53-13.35-10.63t-15.54,2.66a22.68,22.68,0,0,0-8.29,8.12,15.82,15.82,0,0,0-2,10.05l-5.25.3a22.33,22.33,0,0,1,2.8-12.69,27.81,27.81,0,0,1,10.22-10q10.56-6.36,20.43-3.33t16.82,14.73q6.94,11.68,5.11,21.77t-12.2,16.34q-9.71,5.84-19.09,2.65t-15.76-13.93A29.17,29.17,0,0,1,723.6,925.66Zm43.34-1.84q1.6-7.55-3-16.6L730.44,927.4q5.59,8.34,12.73,10.67T757.64,936Q765.35,931.35,766.94,923.82Z"
            />
            <path
              className="cls-1"
              d="M694.61,918.59a9.84,9.84,0,0,1-4-4.64,17,17,0,0,1,2.07-18l4,1.18a13.73,13.73,0,0,0-3.66,7.2,10.34,10.34,0,0,0,.81,7l.35,0a2.93,2.93,0,0,1,1.83-2.12,4.07,4.07,0,0,1,3.27-.08,4.81,4.81,0,0,1,2.73,2.65,5.17,5.17,0,0,1-2.41,6.85A5.21,5.21,0,0,1,694.61,918.59Z"
            />
            <path
              className="cls-1"
              d="M638.24,926.4q-2.67-8.28.3-13.95a15.46,15.46,0,0,1,9.29-7.72,17.21,17.21,0,0,1,5.42-.88,11.85,11.85,0,0,1,4.71.87l.34,5.41a11.08,11.08,0,0,0-4.1-1,12.77,12.77,0,0,0-4.63.63,9.46,9.46,0,0,0-6.19,5.42c-1.09,2.63-1,5.92.27,9.89l18.85,58.77-5.27,1.72Zm24.23,77.33a4.52,4.52,0,0,1-2.29-2.77,4.62,4.62,0,0,1,.26-3.55,4.6,4.6,0,0,1,6.27-2,4.67,4.67,0,0,1,2,6.33A4.57,4.57,0,0,1,666,1004,4.46,4.46,0,0,1,662.47,1003.73Z"
            />
            <path
              className="cls-1"
              d="M589.73,1002.49l-11.64-54.61,4.88-1,2.52,10.76a19.73,19.73,0,0,1,6.24-10.6,26.37,26.37,0,0,1,12.14-5.83q8.89-1.92,15.26,1.9t8.44,13.54l7.68,36-5.42,1.17-7.4-34.73Q619,942.93,604,946.16a20.91,20.91,0,0,0-9.91,4.88,18.35,18.35,0,0,0-5.37,8.59,21,21,0,0,0-.28,10.34l6.68,31.34Z"
            />
            <path
              className="cls-1"
              d="M557.64,952.56a17.62,17.62,0,0,1,9.25,9l-4.3,3.36a13.64,13.64,0,0,0-7.38-7.83,25.77,25.77,0,0,0-12.71-1.8q-7.51.59-11.55,3.81a8.9,8.9,0,0,0-3.66,8,7.56,7.56,0,0,0,4.15,6.29q3.84,2.16,13.14,3.12,11.76,1.22,16.79,4.4t5.53,9.77a13.41,13.41,0,0,1-2,8.22,17.85,17.85,0,0,1-7.07,6.38,27.81,27.81,0,0,1-10.9,3q-8.73.68-14.24-2.25a17.29,17.29,0,0,1-8-9.69l4.54-3.15q4,11.22,17.35,10.19a21.36,21.36,0,0,0,7.9-2.08,14.3,14.3,0,0,0,5.34-4.28,7.86,7.86,0,0,0,1.65-5.33,8.15,8.15,0,0,0-4.31-6.84q-3.95-2.26-13.59-3.2-11.41-1-16.33-4.21a11.93,11.93,0,0,1-5.41-9.66,13.41,13.41,0,0,1,5.14-11.89q5.7-4.75,15.87-5.55A30.39,30.39,0,0,1,557.64,952.56Z"
            />
            <path
              className="cls-1"
              d="M476.26,955.61a15.2,15.2,0,0,1,5.53-3.31,20,20,0,0,1,7.13-1q6.76.19,10.48,4.05t3.49,11.45l-1.06,37.06,11.08.33-.14,4.8-11.08-.33-.4,14-5.59,1.4.45-15.52-20.84-.61.14-4.8,20.83.61,1.05-36.28q.15-5.25-2.26-8.06c-1.61-1.87-4-2.85-7.18-3a13.22,13.22,0,0,0-9.77,3.85Z"
            />
            <path
              className="cls-1"
              d="M386,940.75l1.28-4.29a11.09,11.09,0,0,1,5.54.09,9.29,9.29,0,0,1,5.72,3.43q1.94,2.61,1.14,7.8a16.91,16.91,0,0,1,9.5-5,28.79,28.79,0,0,1,12.12.11q8,1.77,12.05,6.44T436,960.61q-2.76,12.64-24.14,11.81l-17.21-.72-1.05,4.8q-1.43,6.54,1.61,11.1c2,3,5.38,5.08,10.08,6.12q12.89,2.85,19.84-7.73l3.45,4a19.39,19.39,0,0,1-10.06,8.12,25.68,25.68,0,0,1-14.28.44q-9.42-2.09-13.77-7.91T388,976l6.13-28.14q1.26-5.79-3.72-6.88A9.86,9.86,0,0,0,386,940.75Zm24.49,6.39a18.39,18.39,0,0,0-8.22,3.1,10.81,10.81,0,0,0-4.5,6.84l-2.22,10.15,15.43.55q9.14.3,13.78-1.75a9.18,9.18,0,0,0,5.65-6.64,7.92,7.92,0,0,0-1.9-7.46q-2.83-3.09-8.8-4.4A26.42,26.42,0,0,0,410.53,947.14Z"
            />
            <path
              className="cls-1"
              d="M349.84,927a15,15,0,0,1,6.25-1.53,19.68,19.68,0,0,1,7.11,1.12q6.41,2.18,8.84,7t0,12L360.3,980.6l10.5,3.58-1.52,4.55-10.51-3.58-4.43,13.23-5.75-.3,4.93-14.71-19.75-6.72,1.53-4.55,19.75,6.72,11.52-34.4q1.67-5,.17-8.37t-6-4.93a13.11,13.11,0,0,0-10.45.81Z"
            />
            <path
              className="cls-1"
              d="M308.55,906.53a17.81,17.81,0,0,1,2.74,12.68l-5.43.4A13.75,13.75,0,0,0,304,909a25.93,25.93,0,0,0-9.59-8.59q-6.57-3.71-11.71-3.27a8.82,8.82,0,0,0-7.46,4.63,7.64,7.64,0,0,0,0,7.55q2,3.94,9.23,9.93,9.13,7.56,11.57,13t-.76,11.22a13.34,13.34,0,0,1-6.22,5.71,17.69,17.69,0,0,1-9.39,1.37A27.79,27.79,0,0,1,269,947q-7.65-4.31-10.62-9.82A17.39,17.39,0,0,1,257,924.59l5.51-.09q-2.86,11.57,8.85,18.16a21.34,21.34,0,0,0,7.72,2.68,14.19,14.19,0,0,0,6.8-.58,7.78,7.78,0,0,0,4.31-3.53,8.22,8.22,0,0,0,.17-8.09q-2.06-4.1-9.56-10.24-9-7.23-11.29-12.63a12,12,0,0,1,.8-11.06,13.29,13.29,0,0,1,10.82-7q7.37-.78,16.26,4.23A30.63,30.63,0,0,1,308.55,906.53Z"
            />
            <path
              className="cls-1"
              d="M203.27,893.1q-1-10.46,7-21.28t18.3-12.77q10.26-1.95,20.1,5.48t10.86,17.9q1,10.47-7,21.28t-18.3,12.77q-10.24,2-20.1-5.48T203.27,893.1Zm29.82,18.12q8.29-1.83,15-10.86t6.14-17.56q-.57-8.53-8.39-14.43t-16.1-4.06q-8.28,1.83-15,10.86t-6.14,17.56q.59,8.53,8.39,14.43T233.09,911.22Z"
            />
            <path
              className="cls-1"
              d="M123.49,816.13q0-7.95,7.39-14.72L157.42,777l3.74,4.13-26.14,24q-5.9,5.42-6.06,11.46t4.77,11.49a18.94,18.94,0,0,0,8.42,5.49,18.74,18.74,0,0,0,9.95.38,20.08,20.08,0,0,0,9.3-5L185,807.33l3.74,4.13-26.14,24q-5.9,5.41-6.06,11.46t4.77,11.49a19.52,19.52,0,0,0,8.23,5.44,18.4,18.4,0,0,0,10.06.51,21,21,0,0,0,9.78-5.28l23.18-21.29,3.74,4.12-41,37.63L172,875.82l8-7.77a19.73,19.73,0,0,1-12.09.07,24.64,24.64,0,0,1-16.42-18.32,17.65,17.65,0,0,1,2.12-12.07,18,18,0,0,1-12.58.44,25,25,0,0,1-11.28-7.25Q123.48,824.08,123.49,816.13Z"
            />
            <path
              className="cls-1"
              d="M103.84,737,127,774.73q8.38-5.88,10.34-13.61t-2.87-15.61a22.64,22.64,0,0,0-8.19-8.23,15.45,15.45,0,0,0-10-1.85l-.37-5.28a21.87,21.87,0,0,1,12.63,2.63,27.8,27.8,0,0,1,10.08,10.14q6.47,10.55,3.61,20.53t-14.35,17.17q-11.51,7.17-21.54,5.48T90,774.06q-6-9.69-2.93-19.2t13.58-16.09A31,31,0,0,1,103.84,737Zm2.49,43.64q7.52,1.49,16.43-3.29l-20.53-33.43q-8.2,5.77-10.41,13t2.28,14.54Q98.83,779.17,106.33,780.66Z"
            />
            <path
              className="cls-1"
              d="M44.6,654.73q5-9,17.77-13.49t22.24-.5q9.47,4,13.32,15.14a23,23,0,0,1,.84,13,20.62,20.62,0,0,1-6.3,10.66L125.56,668l1.82,5.28L53,699.25l-1.64-4.74,11.73-4.46a19.75,19.75,0,0,1-12-4,22.89,22.89,0,0,1-7.64-11.1Q39.61,663.74,44.6,654.73Zm38-8.85q-7.8-3.06-18.38.64t-14.82,11q-4.24,7.29-1.08,16.46,2.84,8.24,10.13,11.35t17.08.18l3.77-1.32q9.49-3.79,13.3-10.8a18.22,18.22,0,0,0,1-15.23Q90.38,649,82.57,645.88Z"
            />
            <path
              className="cls-1"
              d="M29.58,586.18q6.27-8.4,19.45-10.91t22.06,3Q80,583.83,82.25,596t-4,20.59Q72,625,58.82,627.51t-22.06-3Q27.88,619,25.6,606.78T29.58,586.18Zm9.86,33.65q7.35,4.29,18.35,2.19t16.3-8.78q5.28-6.69,3.48-16.35T68.41,583q-7.35-4.29-18.35-2.2t-16.29,8.79q-5.3,6.69-3.48,16.35T39.44,619.83Z"
            />
            <path
              className="cls-1"
              d="M8.32,562.07a4.51,4.51,0,0,1-3.25,1.57,4.55,4.55,0,0,1-3.48-1A4.68,4.68,0,0,1,0,559.37a4.65,4.65,0,0,1,1-3.5,4.55,4.55,0,0,1,3.26-1.57,4.51,4.51,0,0,1,3.48,1,5.19,5.19,0,0,1,.58,6.79ZM75,550l.48,5.56-55.22,4.83-.48-5.56Z"
            />
            <path
              className="cls-1"
              d="M22.71,489.61q5.41-5.78,15.39-5.62l35.91.59-.09,5.58-34.14-.55q-8.87-.15-13.26,4.14T22,505.62a20.83,20.83,0,0,0,2.27,10,18.45,18.45,0,0,0,7,7.43A21,21,0,0,0,42.08,526l31.26.51L73.25,532l-55.43-.9.09-5,11.2,0a19.34,19.34,0,0,1-9-8.36A25.12,25.12,0,0,1,17.14,505Q17.29,495.38,22.71,489.61Z"
            />
            <path
              className="cls-1"
              d="M76.17,437.77a15.39,15.39,0,0,1,2.71,5.87,20.24,20.24,0,0,1,.3,7.25q-.86,6.75-5,10.1T62.5,463.36L26,458.62,24.57,469.7l-4.72-.61L21.26,458,7.52,456.22l-.83-5.74,15.28,2,2.66-20.83,4.73.62L26.7,453.08l35.74,4.64q5.16.67,8.18-1.47c2-1.43,3.21-3.73,3.62-6.91a13.34,13.34,0,0,0-2.84-10.16Z"
            />
            <path
              className="cls-1"
              d="M76.58,420.29a9.71,9.71,0,0,1,6.07-.4,16.84,16.84,0,0,1,13,12.47l-3.34,2.51a13.73,13.73,0,0,0-3.5-7.28A10.12,10.12,0,0,0,82.73,424l-.18.3a3,3,0,0,1,.58,2.74,4.19,4.19,0,0,1-1.91,2.69,4.73,4.73,0,0,1-3.74.6,4.93,4.93,0,0,1-3.23-2.2,5,5,0,0,1-.75-3.86A5.27,5.27,0,0,1,76.58,420.29Z"
            />
            <path
              className="cls-1"
              d="M66.79,314.11q9.64-3.53,22.2,1.54T106.12,330q4.57,9.24.21,20.23a22.77,22.77,0,0,1-7.83,10.38,20.19,20.19,0,0,1-11.69,3.89l32.53,13.11-2.06,5.18L44.19,353.3l1.86-4.66L57.8,353a20.06,20.06,0,0,1-6.45-11,23,23,0,0,1,1.45-13.43Q57.15,317.63,66.79,314.11Zm34.44,18.38q-3.9-7.47-14.3-11.65T68.6,319.35Q60.67,322,57.08,331.07q-3.21,8.08.27,15.26t12.79,11.41l3.7,1.49q9.64,3.4,17.07.62T101.55,349Q105.14,339.95,101.23,332.49Z"
            />
            <path
              className="cls-1"
              d="M100,252.4q10.2-2.22,21.79,4.58t14.71,16.84q3.12,10-3.08,20.75T117,307.5q-10.22,2.24-21.8-4.58t-14.7-16.84q-3.12-10,3.08-20.74T100,252.4ZM85.59,284.33q2.76,8.1,12.44,13.77t18,4.11q8.36-1.56,13.26-10.06t2.15-16.58q-2.78-8.08-12.45-13.76t-18-4.11q-8.34,1.56-13.25,10.05T85.59,284.33Z"
            />
            <path
              className="cls-1"
              d="M154.28,255.75a17.4,17.4,0,0,1-12.85.82l.43-5.46a13.48,13.48,0,0,0,10.71-.24,25.94,25.94,0,0,0,9.88-8.25q4.63-6,5-11.17a8.91,8.91,0,0,0-3.41-8.12,7.46,7.46,0,0,0-7.41-1.14q-4.17,1.4-11.14,7.69-8.81,8-14.53,9.55T120,237a13.37,13.37,0,0,1-4.67-7,18,18,0,0,1,.08-9.56,28,28,0,0,1,5.14-10.12q5.37-7,11.24-9.1a17,17,0,0,1,12.51.54l-.74,5.5q-10.92-4.59-19.16,6.08a21.87,21.87,0,0,0-3.8,7.28,14.62,14.62,0,0,0-.45,6.86,7.88,7.88,0,0,0,2.81,4.82,8,8,0,0,0,7.92,1.39q4.32-1.41,11.49-8,8.43-7.83,14.09-9.34a11.76,11.76,0,0,1,10.73,2.47,13.41,13.41,0,0,1,5.27,11.83q-.34,7.45-6.61,15.56A30.52,30.52,0,0,1,154.28,255.75Z"
            />
            <path
              className="cls-1"
              d="M188.61,213.77a17.44,17.44,0,0,1-12.86-.77l1.09-5.37a13.46,13.46,0,0,0,10.66,1.09,26.07,26.07,0,0,0,10.81-7q5.33-5.38,6.29-10.48a8.93,8.93,0,0,0-2.4-8.48,7.48,7.48,0,0,0-7.22-2q-4.3.88-12,6.26-9.7,6.81-15.59,7.69t-10.51-3.77a13.4,13.4,0,0,1-3.77-7.58,18,18,0,0,1,1.23-9.48,28.18,28.18,0,0,1,6.34-9.41q6.18-6.24,12.26-7.64a17.05,17.05,0,0,1,12.35,2.07l-1.41,5.38q-10.28-5.91-19.75,3.66a21.91,21.91,0,0,0-4.65,6.76,14.56,14.56,0,0,0-1.28,6.76,7.86,7.86,0,0,0,2.2,5.13,8.07,8.07,0,0,0,7.69,2.35q4.47-.87,12.37-6.49,9.33-6.74,15.12-7.53A11.81,11.81,0,0,1,206,178.67a13.43,13.43,0,0,1,3.78,12.4q-1.25,7.35-8.44,14.62A30.6,30.6,0,0,1,188.61,213.77Z"
            />
            <path
              className="cls-1"
              d="M183.32,128.43a5.15,5.15,0,0,1-4.34-5.22,4.65,4.65,0,0,1,8.33-2.59,4.65,4.65,0,0,1,1.2,3.43,4.6,4.6,0,0,1-1.78,3.17A4.52,4.52,0,0,1,183.32,128.43Zm48.07,48.09-4.25,3.59-35.62-42.79,4.25-3.59Z"
            />
            <path
              className="cls-1"
              d="M254.48,98.81q9.93,2.52,17.53,13.8t6.24,21.51Q276.9,144.35,267.17,151a22.66,22.66,0,0,1-12.67,4.27,19.69,19.69,0,0,1-12-3.89l6.7,10.54-4.13,2.82L199.66,97.26l4.58-3.14,20.91,31.07a20.72,20.72,0,0,1,1.27-12.34,22.72,22.72,0,0,1,8.4-9.92Q244.54,96.29,254.48,98.81Zm18.3,34.66q.93-8.39-5.36-17.73t-14.36-11.58Q245,101.93,237,107.37a18.11,18.11,0,0,0-8.26,12.8q-1.11,7.9,4.25,16.66l2.24,3.33q6.09,8.26,13.79,10.15t14.86-3Q271.86,141.85,272.78,133.47Z"
            />
            <path
              className="cls-1"
              d="M298.59,115c1.59,3,3.19,4.91,4.8,5.65s3.51.52,5.73-.67a17.52,17.52,0,0,0,3.18-2.07,19.06,19.06,0,0,0,2.56-2.76l1.81,5.1a17.38,17.38,0,0,1-5.79,4.74q-5.28,2.82-9.42,1.18T294,118.12L262,57.54l4.9-2.62Z"
            />
            <path
              className="cls-1"
              d="M343.93,119.61q.06,8.22-2.24,12.52a14.71,14.71,0,0,1-7.61,6.55q-6.34,2.67-11.3.29l.1-5.25q4,2.67,9.31.42a10.25,10.25,0,0,0,5.25-4.58q1.67-3.06,1.58-9.32l-.15-9.63-10.64-10.52L295.86,68.23l5.82-2.47,31.9,32.43L339,104l-.32-8.58-1-44.87,5.52-2.33Z"
            />
            <path
              className="cls-1"
              d="M463.85,43.41a139.5,139.5,0,0,0-16.91,18.82,135.48,135.48,0,0,0-12.39,20.86l-.44.08a143.52,143.52,0,0,0-18.69-15.45,134.58,134.58,0,0,0-22.14-12l2.83-5.14a141.52,141.52,0,0,1,20.06,11.35,81.62,81.62,0,0,1,14.37,12.23q-1.83-7.38-3.25-15.64L419,10.19l5.47-.95,8.29,48.32q1.44,8.37,2.18,15.94a76.42,76.42,0,0,1,9.39-16.37,153.21,153.21,0,0,1,15.15-17.6Z"
            />
          </g>
          <g id="Title" className={s.title}>
            <path
              className="cls-2"
              d="M273.94,341.39V509.63l-59.4,15.92V462.84L168.41,475.2v62.71L109,553.83V385.59l59.4-15.92v61.94l46.13-12.36V357.31Z"
            />
            <path
              className="cls-2"
              d="M301.34,361.07q-7.26-3.66-7.27-16.91t7.27-20.81q7.26-7.56,24.6-12.2t24.6-1q7.26,3.68,7.26,16.92t-7.26,20.81q-7.28,7.56-24.6,12.2T301.34,361.07Zm53.91,126.78-58.63,15.71V376.1l58.63-15.71Z"
            />
            <path
              className="cls-2"
              d="M479.9,327.5l-4.84,49.22a15,15,0,0,0-6.63-1.15A37.13,37.13,0,0,0,459,377q-11.73,3.14-18.48,10.56a25.94,25.94,0,0,0-6.76,18.12v61.18l-58.62,15.71V355.06l52.76-14.13,1.28,31.52q9.17-36.87,37.47-44.46Q475.56,325.6,479.9,327.5Z"
            />
            <path
              className="cls-2"
              d="M620.61,364.23,539.55,386q1.28,9.1,5.35,11.31t12.24,0q12.48-3.35,15.29-15.06l47.16.11q-4.33,19.51-21.29,33.49T557.14,436.3q-36.21,9.7-54.94-2.36t-18.73-44.18q0-31.61,18.48-53.89t52.13-31.3q32.88-8.82,50.34,3.33t17.46,40.7A77.24,77.24,0,0,1,620.61,364.23ZM544.14,349.8q-3.58,4.79-4.59,14.49l27.53-7.38q-.76-8.46-3.7-11.88t-9-1.78A18,18,0,0,0,544.14,349.8Z"
            />
            <path
              className="cls-2"
              d="M251.12,556.67q20.26,16.37,20.27,56.9t-20.27,67.75q-20.26,27.23-59.52,37.74L109,741.19V573l82.59-22.13Q230.86,540.3,251.12,556.67ZM204.86,663.51q5.87-11.25,5.86-33.69t-5.86-30.55q-5.87-8.12-21.67-3.88l-14.78,4V683l14.78-4Q199,674.77,204.86,663.51Z"
            />
            <path
              className="cls-2"
              d="M428.41,623.24l-4.59,31A47.79,47.79,0,0,1,405,664.44q-15.56,4.17-26,1.49t-14-12.18A60.18,60.18,0,0,1,347.73,674q-11.34,8.78-28.16,13.29-19.89,5.32-29.57.14t-9.69-20q0-16.07,11.47-28.69T330,613.15l25.23-12.37v-5.1q0-5.85-2.93-8.39c-1.95-1.68-4.8-2-8.54-1a18,18,0,0,0-9.68,6q-3.59,4.4-3.83,13.13L281.84,611q2-22.47,18.35-38.95T347.1,547.4q33.9-9.09,50.09-.43t16.18,35.18v35.69q0,10.45,8.67,8.12A18.62,18.62,0,0,0,428.41,623.24Zm-76.22,20.29a16.18,16.18,0,0,0,3.06-10.12V621.17L346.84,626a25,25,0,0,0-8.41,7.22,15.33,15.33,0,0,0-3.06,9.1q0,4.35,2.55,6a7.79,7.79,0,0,0,6.37.59A14.35,14.35,0,0,0,352.19,643.53Z"
            />
            <path
              className="cls-2"
              d="M540.83,498.55,536,547.77a14.84,14.84,0,0,0-6.63-1.16,37.13,37.13,0,0,0-9.43,1.38q-11.73,3.15-18.48,10.56a26,26,0,0,0-6.75,18.12v61.18l-58.63,15.71V526.11L488.82,512l1.28,31.52q9.18-36.87,37.47-44.45C533.52,497.44,537.93,497.28,540.83,498.55Z"
            />
            <path
              className="cls-2"
              d="M654,468.22l-4.84,49.22a14.86,14.86,0,0,0-6.63-1.15,36.69,36.69,0,0,0-9.43,1.38q-11.73,3.14-18.48,10.56a25.89,25.89,0,0,0-6.76,18.12v61.18l-58.63,15.71V495.78L602,481.64l1.27,31.53q9.18-36.88,37.48-44.46Q649.66,466.33,654,468.22Z"
            />
            <path
              className="cls-2"
              d="M794.71,505l-81.06,21.72c.84,6.06,2.63,9.84,5.35,11.31s6.79,1.5,12.23,0q12.49-3.35,15.3-15.06l47.16.11q-4.34,19.51-21.29,33.49T731.23,577q-36.19,9.71-54.93-2.36t-18.73-44.18q0-31.61,18.48-53.89t52.13-31.3q32.88-8.82,50.34,3.33T796,489.32A77.24,77.24,0,0,1,794.71,505Zm-76.48-14.43q-3.57,4.78-4.58,14.48l27.53-7.37q-.76-8.46-3.7-11.89T728.43,484A18.1,18.1,0,0,0,718.23,490.52Z"
            />
            <path
              className="cls-2"
              d="M938.86,400.05q10.57,8.38,10.57,28.77V516l-58.62,15.71V459.83c0-5.61-.69-9.29-2-11.05s-3.66-2.21-6.89-1.35a14.12,14.12,0,0,0-9.3,7.34q-2.94,5.64-2.93,15.83v66.78L811,553.09V425.64l52.76-14.14,1.28,27.44a67.26,67.26,0,0,1,16.82-27.07,62.31,62.31,0,0,1,27.53-15.15Q928.28,391.67,938.86,400.05Z"
            />
          </g>
          <polygon
            id="Check"
            className={s.checkmark}
            points="827.83 592.38 687.78 728.33 661.8 678.85 623.45 717.17 676.88 820.45 866.17 630.7 827.83 592.38"
          />
        </g>
      </svg>
    </div>
  );
};

export default Logo;
