import React, { useContext } from "react";
import { navigate } from "gatsby-link";
import Seo from "../components/seo";
import Layout from "../components/layout";
import { ReasonContext } from "../context/reasonProvider";

import ContentWrapper from "../components/contentWrapper";
import Button from "../components/button";
import Logo from "../components/logo";
import * as s from "./styles.module.scss";

const HomePage = () => {
  const { reasonNumber, nextReasonNumber } = useContext(ReasonContext);

  /**
   *
   * @param number a number - Preferably your reason number
   * @returns a string with padded zero if below 10
   */
  const padNumber = (number: number): string => {
    return number >= 10
      ? number.toString()
      : number.toString().padStart(2, "0");
  };

  const goToReasons = () => {
    nextReasonNumber();
    navigate(`/reason/${padNumber(reasonNumber)}`);
  };

  const goToWork = () => {
    navigate(`/work`);
  };

  return (
    <Layout background>
      <Seo
        title="Start"
        description="You should probably consider hiring Darren as a designer... Here's 50+ reasons why."
      />
      <ContentWrapper fillAvailableHeight>
        <div className={s.home}>
          <div className={s.home__logo}>
            <Logo />
          </div>
          <div className={s.home__options}>
            <Button
              uppercase
              border
              ariaLabel="Why you should hire Darren."
              content={{
                btnText: "Learn why you should hire me...",
                onClick: goToReasons,
              }}
            />
            <Button
              uppercase
              border
              ariaLabel="See my work!"
              content={{
                btnText: "Or just check out my work...",
                onClick: goToWork,
              }}
            />
          </div>
        </div>
      </ContentWrapper>
    </Layout>
  );
};

export default HomePage;
